import * as React from "react";
import { Map, MapMouseEvent } from "@vis.gl/react-google-maps";
import './styles.scss';
import { Circle } from "./circle";

interface Props {
  latitude: string,
  longitude: string,
  serviceAreaRadiusMileage: string,
  mapId?: string,
}
const DEFAULT_USA_COORDS = {
  lat: 39.8097343, lng: -98.5556199
};

const METERS_TO_MILES = 1609.34;

const ContractorMarketMap: React.FC<Props> = ({ latitude, longitude, serviceAreaRadiusMileage: propsMileage, mapId }) => {
  const [latLng, setLatLng] = React.useState({ lat: Number.parseFloat(latitude), lng: Number.parseFloat(longitude) });
  const [serviceAreaRadiusMileage, setServiceAreaRadiusMileage] = React.useState(Number.parseInt(propsMileage) || 50);

  return (
    <div className="contractorMarketMapContainer">
      <div className="mapContainer">
        <Map
          id="contractor-market-map"
          defaultCenter={latLng.lat && latLng.lng ? latLng : DEFAULT_USA_COORDS}
          defaultZoom={4}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          mapId={mapId}
          draggableCursor="crosshair"
          onClick={(e) => setLatLng(e.detail.latLng)}
        >
          {latLng.lat && latLng.lng && (
            <Circle center={latLng} radius={serviceAreaRadiusMileage * METERS_TO_MILES} />
          )}
        </Map>

      </div>
      <div>
        <ol>
          <li>
            <label className="label">Latitude</label>
            <input id="contractor_market_latitude" name="contractor_market[latitude]" value={latLng?.lat} readOnly type="number" required />
          </li>
          <li>
            <label className="label">Longitude</label>
            <input id="contractor_market_longitude" name="contractor_market[longitude]" value={latLng?.lng} readOnly type="number" required />
          </li>
          <li>
            <label className="label">Service area radius mileage</label>
            <span className="rangeValueContainer">
              <b>{serviceAreaRadiusMileage}&nbsp;&nbsp;</b>
              <input id="contractor_market_service_area_radius_mileage"
                     name="contractor_market[service_area_radius_mileage]"
                     onChange={(e) => setServiceAreaRadiusMileage(e.target.valueAsNumber)}
                     value={serviceAreaRadiusMileage}
                     type="range"
                     min={10}
                     max={200} />
            </span>

          </li>
        </ol>

      </div>
    </div>
  );
};

export default ContractorMarketMap;
